import React from "react"

import Layout from "../components/layout"
import Heading1 from "../components/Heading1"
import Heading2 from "../components/Heading2"
import NavigationList from "../components/top/NavigationList"
import MediaList from "../components/top/MediaList"
import ArticleList from "../components/article/ArticleList"
import SEO from "../components/seo"

import { graphql } from 'gatsby'

export default ({data}) => (
  <Layout>
    <SEO title="個人開発者 大波 誠(おおなみ まこと)のブログです。" />
    
    <Heading1>
      個人開発者の自由帳
    </Heading1>
    <NavigationList></NavigationList>
    <Heading2 text="外部メディア"></Heading2>
    <MediaList></MediaList>
    <Heading2 text="記事一覧"></Heading2>
    <ArticleList articles={data.articleList.edges}></ArticleList>
  </Layout>
)

export const query = graphql`
  query {
    articleList:
      allMarkdownRemark (
        filter: {fileAbsolutePath: {regex: "/\/articles\//"}}
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 300
      )
      {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              date
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
  }
`
