import React from 'react'
import styles from "./index.module.scss"
import ArticleCard from "../ArticleCard"

const ArticleList = ({articles}) => (
  <div className={styles.container}>
    {articles.map(({node}, index) => (
      <ArticleCard node={node} key={index}></ArticleCard>
    ))}
  </div>
)
export default ArticleList
