import React from "react"
import styles from "./index.module.scss"

const MediaList = () => (
  <ul>
    <li>
      <a
        href="https://www.youtube.com/channel/UCMPIUab4UhzmXd8pn3SmlBw/featured"
        className={styles.link}
      >
        YouTube / おおなみ開発
      </a>
    </li>
    <li>
      <a
        href="https://stand.fm/channels/5e66157e81d4e84e1f696788"
        className={styles.link}
      >
        stand.fm / おおなみ開発レポート
      </a>
    </li>
  </ul>
)
export default MediaList
