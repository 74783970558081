import React from 'react'
import styles from "./index.module.scss"

const NavigationList = () => (
<ul>
  <li><a href="/works" className={styles.link}>作品/ポートフォリオ</a></li>
  <li><a href="/about" className={styles.link}>プロフィール</a></li>
  <li><a href="/contact" className={styles.link}>お問い合わせ</a></li>
</ul>
)
export default NavigationList
